<template>
  <!-- 工地维度--基本信息 -->
  <div>
    <s-search
      :queryItems="queryItems"
      @fetchData="fetchUsers"
      ref="SSearch"
    ></s-search>
    <div class="table-button">
      <!-- <a-button
        type="plain"
        icon="cloud-download"
        @click="handleExport"
        v-if="isShowBtns.indexOf('SiteInfo-/api/worker/attendanceexport') > -1"
        >导出</a-button
      > -->
      <a-button type="plain" icon="redo" @click="$refs.table.refresh(true)"
        >刷新</a-button
      >
      <columns-set
        tableName="EvaluationRecord"
        :columns="columns"
        @changeColumns="changeColumns"
      ></columns-set>
    </div>
    <s-table
      ref="table"
      rowKey="ws_name"
      :columns="tableColumns"
      :data="loadData"
      :showPagination="true"
      :scroll="{ x: 900, y: 'calc(82vh - 100px )' }"
      tableLayout="fixed"
      @change="changeTable"
    >
      <span slot="ws_name" slot-scope="text">
        <a-tooltip placement="topLeft">
          <template slot="title">
            <span>{{ text }}</span>
          </template>
          <span style="cursor: pointer">{{
            text.replace(/直辖市|-北京|-天津/g, "")
          }}</span>
        </a-tooltip>
      </span>
    </s-table>
  </div>
</template>

<script>
import { STable, SSearch, ColumnsSet } from "@/components";
import { SelectOptions } from "@/config/SelectOptions.js";
import { GetSiteWsdetail } from "@/api/home";

// 搜索条件
const queryItems = [
  {
    label: "工地名称",
    value: "ws_name",
    type: "input",
    defaultValue: "",
  },
  {
    label: "姓名",
    value: "ws_owner_name",
    type: "input",
    defaultValue: "",
  },
  {
    label: "分公司",
    value: "bc_id",
    type: "select",
    selectOption: "CompanyList",
  },
];

const columns = [
  {
    title: "工地名称",
    width: "150px",
    scopedSlots: { customRender: "ws_name" },
    dataIndex: "ws_name",
    align: "center",
    ellipsis: true,
  },
  {
    title: "业主",
    width: "80px",
    scopedSlots: { customRender: "ws_owner_name" },
    dataIndex: "ws_owner_name",
    align: "center",
  },
  {
    title: "电话",
    width: "110px",
    scopedSlots: { customRender: "ws_owner_phone" },
    dataIndex: "ws_owner_phone",
    align: "center",
  },
  {
    title: "户型",
    width: "80px",
    scopedSlots: { customRender: "ws_unittype" },
    dataIndex: "ws_unittype",
    align: "center",
  },
  {
    title: "装修动态",
    width: "80px",
    scopedSlots: { customRender: "ws_proce" },
    dataIndex: "ws_proce",
    align: "center",
  },
  {
    title: "面积",
    width: "80px",
    scopedSlots: { customRender: "ws_q_area" },
    dataIndex: "ws_q_area",
    align: "center",
  },
  {
    title: "服务分公司",
    width: "110px",
    scopedSlots: { customRender: "ws_bc_name" },
    dataIndex: "ws_bc_name",
    align: "center",
  },
  {
    title: "装修风格",
    width: "80px",
    scopedSlots: { customRender: "serial" },
    dataIndex: "serial",
    align: "center",
  },
  {
    title: "管家",
    width: "80px",
    scopedSlots: { customRender: "ws_gj_name" },
    dataIndex: "ws_gj_name",
    align: "center",
  },
  {
    title: "项目经理",
    width: "80px",
    scopedSlots: { customRender: "ws_jl_name" },
    dataIndex: "ws_jl_name",
    align: "center",
  },
];
const orderParam = ["ws_name", "ws_owner_name", "bc_id", "pageNo", "pageSize"];

export default {
  name: "SiteInfo",
  components: {
    STable,
    SSearch,
    ColumnsSet,
  },
  data() {
    this.queryItems = queryItems;
    this.columns = columns;
    return {
      tableColumns: [],
      // 查询参数
      queryParam: {},
      isShowBtns: [],
    };
  },
  created() {
    this.tableColumns = columns;
    this.changeColumns();
    this.filters = SelectOptions;
  },
  mounted() {
    this.isShowBtns = sessionStorage.getItem("isShowBtns").split(",");
  },
  methods: {
    // 自定义表头列
    changeColumns() {
      let ColumnsSet = JSON.parse(
        localStorage.getItem("EvaluationRecordColumnsSet")
      );
      let value = ColumnsSet ? ColumnsSet : [];
      let selectColumns = columns.filter((el) => value.includes(el.dataIndex));
      this.tableColumns = selectColumns.length ? selectColumns : columns;
    },
    // 加载数据方法 必须为 Promise 对象
    loadData(parameter) {
      if (parameter) {
        this.pageNo = parameter.pageNo;
        this.pageSize = parameter.pageSize;
      } else {
      }
      const param = Object.assign({}, parameter, this.queryParam);
      const requestParameters = this.$order(param, orderParam);

      const params = {
        ...requestParameters,
        verify: this.$verify(requestParameters, orderParam),
      };
      return GetSiteWsdetail(params).then((res) => {
        res.data.pageNo = res.data.page;
        res.data.totalCount = res.data.sum;
        console.log(res.data);
        return res.data;
      });
    },
    fetchUsers(value) {
      this.queryParam = value;
      this.$refs.table.refresh(true);
    },
    changeTable(pagination, filters, sorter, { currentDataSource }) {
      console.log(pagination, filters, sorter, { currentDataSource });
    },
    handleExport() {
      this.confirmLoading = false;
      this.visible = true;
    },
  },
};
</script>
<style lang="less" scoped>
</style>